@import 'katex/dist/katex.min.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    @apply bg-gray-50 text-gray-900;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded font-semibold text-sm shadow-sm transition-colors duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
  
  .btn-primary {
    @apply bg-green-600 text-white hover:bg-green-700 focus:ring-green-500;
  }
  
  .card {
    @apply bg-white rounded-lg shadow-md overflow-hidden transition-shadow duration-300 hover:shadow-lg;
  }
}

@layer utilities {
  .text-shadow {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
}

/* Custom styles */
.gradient-bg {
  background: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
}

.custom-shape-divider-bottom-1620727514 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1620727514 svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1620727514 .shape-fill {
  fill: #FFFFFF;
}